body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.menu_item.active .submenu {
  display: block;
}

.submenu {
  display: none;
}

/* CSS */
.scroll-container {
  overflow-x: scroll;
}

.scroll-container::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #f1f1f1;
  border-radius: 6px;
}

/* .scroll-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
} */

.scroll-container::-webkit-scrollbar-thumb:hover {
  /* background-color: #555; */
  cursor: pointer;
}

/* .scroll-container::-webkit-scrollbar-thumb:active {
  background-color: #4475B7;
} */


/* Set default background color of scrollbar track */
.scroll-container::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Set the width of the scrollbar */
/* .scroll-container {
  scrollbar-width: thin;
} */

/* Set background color of scrollbar track when scrollbar is visible */
.scrollbar-visible .scroll-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}


/*****************************Mithlesh css**************************************************/

.client_panel_list_table {
  width: 100%;
  border-collapse: collapse;
}

.client_panel_list_table th,
.client_panel_list_table td {
  word-wrap: break-word;
}

.client_panel_list_table input {
  width: 100%;
  box-sizing: border-box;
}



.client_locationDate .react-datepicker-wrapper {
  width: 100%;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  /* This prevents the button from being clickable */
}

.active>.page-link,
.page-link.active {
  z-index: 3;
  color: #fff !important;
  background-color: #4577b7 !important;
  border-color: #4577b7 !important;
}

.page-link {
  color: #4577b7 !important;
  border-radius: 4px;
}

.page-link:focus {
  box-shadow: none !important;
}

.select-page {
  background: #f7f4ff !important;
  border: none !important;
  border-radius: 4px !important;
  color: #777 !important;
  font-weight: 400 !important;
  --bs-pagination-padding-y: 0 !important;
}

/* NO Permission CSS */

.message {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 50vw;
  margin: auto;
}

.message svg {
  font-size: 15vw;
  color: #777;
}

.message h1 {
  margin: 0;
  color: #666;
  font-size: 2.5vw;
  margin: 1.5vw 0vw;
}

.message p {
  margin-top: 10px;
  color: #666;
}

div.css-1h51icj-MuiAutocomplete-root .MuiAutocomplete-inputRoot {
  background: #F7F4FF;
  border: none !important;
  height: 35px;
}

/* Remove outline and border from the input field */
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-notchedOutline {
  border: none;
  /* Remove border */
}

/* Remove box-shadow when focused */
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: none;
  /* Remove border */
  box-shadow: none;
  /* Remove box-shadow */
}

/* Remove outline and border from the input field */
.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-notchedOutline {
  border: none;
  /* Remove border */
}

/* Remove box-shadow when focused */
.MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline {
  border: none;
  /* Remove border */
  box-shadow: none;
  /* Remove box-shadow */
}

/* Remove outline and border on hover */
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: none;
  /* Remove border */
}

.custom-icon-class {
  border: none;
  color: #b1b1b1;
  font-weight: 400;
  font-size: 40px;
  margin-top: 10px;
  margin-bottom: 0px;
  /* Remove the border */
}

.custom-label {
  border-radius: 4px;
  width: 100%;
  color: #777;
  font-family: Geometria;
  font-size: 0.93vw;
  font-weight: 400;
  font-size: 0.875rem;
  margin-top: 0px !important;

}

.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px 1vw !important;

}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 0px 4px 0px 5px !important;
  margin-top: 0;
}

.me_height {
  height: 35px;
  margin-bottom: 1.56vw;
}

.css-18nc3u2 .MuiOutlinedInput-root {
  padding: 0px !important;
}

.css-18nc3u2 .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 4px !important;
  background: #f7f4ff;
  color: #777;
  font-weight: 400 !important;
  font-family: 'Geometria';
  margin-top: 0;
}

.client_panel_list_table.user-checkbox input[type=checkbox] {
  visibility: visible;
}

.client_panel_option button {
  border-radius: 4px;
  border: 1px solid #C9D7E9;
  background: #F7F4FF !important;
  padding: 0 1vw !important;
  width: 100% !important;
}

.client_panel_column_chooser button {
  border-radius: 4px;
  border: 1px solid #C9D7E9;
  background: #F7F4FF !important;
  padding: 0 1vw !important;
  width: 100% !important;
}

.client_panel_new button {
  border-radius: 4px;
  background: #4475B7 !important;
  color: #fff !important;
}

.dropdown-menu {
  display: none;
  /* Other styles */
}

.dropdown-menu.open {
  display: block;
}

ul.dropdown-menu {
  border-radius: 4px;
  border: 0.5px solid #C9D7E9;
  background: #FFF;
  padding: 1vw 0.5vw !important;
  margin: 1vw 0;
  width: 19vw;
}

ul.dropdown-menu li img {
  margin-right: 0.8vw;
  /* Adjust the spacing between the image and text */
  width: 20px;
  /* Adjust the image width as needed */
  height: 20px;
  /* Adjust the image height as needed */
}

ul.dropdown-menu li {
  padding: 0.8vw 0.8vw;
  display: flex;
  align-items: center;

}

ul.dropdown-menu li label {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  /* Add space between checkbox and label */
}

/* ul.dropdown-menu li input[type="checkbox"] {
  margin-right: 12px;
} */

ul.dropdown-menu li .checkbox-label {
  margin-left: 4px;
  /* Add space between checkbox and label */
}

ul.dropdown-menu li:hover {
  border-radius: 4px;
  background: #F7F4FF;
}

.client_panel_column_chooser ul.dropdown-menu {
  background: #F7F4FF;
}

.client_panel_column_chooser ul.dropdown-menu {
  margin: 0px;
  width: 13.2vw;
  padding: 0.5vw 0.5vw !important;
  border: 0 solid #C9D7E9;
  border-bottom-width: 0.5px;
  border-left-width: 0.5px;
  border-right-width: 0.5px;
}

.badge-primary {
  background: #4577b7;
  padding: 6px 8px;
}

.badge-warning {
  background: #ffc107;
  padding: 6px 8px;
}

.label_main {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;

}

.btn-theme-primary {
  background: #4476b7 !important;
  border-color: #4476b7 !important;
  margin: 2px;
  width: 1.74vw;
  padding: 0px !important;
  height: 1.74vw;
  padding-top: 5px !important;
  margin: 4px;
}

.btn-danger {
  width: 29px;
  padding: 0px !important;
  height: 29px;
  padding-top: 5px !important;
  margin: 4px;
}

.ml-10 {
  margin-top: -10px;
  margin-left: 10px;
}

.p-10 {
  padding: 10px 0px;
}

.custom-pagination {
  display: flex;
  justify-content: end;
  align-items: center;

  .btn-theme-primary {
    padding-top: 0px !important;
  }

  .page-btn {
    width: 25px;
    padding: 0px;
    height: 29px;
  }

  .active {
    background: #4577b7 !important;
    border-color: #4577b7 !important;
    color: #fff !important;
  }
}

/* .form-switch .form-check-input:checked {
  background: #4476b7!important;
  border-color: #4476b7!important;
} */
/* Hide the default checkbox */
input[type=checkbox] {
  visibility: hidden;
}

.form-switch input[type=checkbox] {
  visibility: inherit !important;
}

td input[type=checkbox] {
  visibility: inherit !important;
}

/* Creating a custom checkbox
based on demand */
.geekmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background: #4475B7;
}

/* Specify the background color to be
shown when checkbox is checked */
.label_main input:checked~.geekmark {
  background-color: #4475B7;
}

/* Checkmark to be shown in checkbox */
/* It is not be shown when not checked */
.geekmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Display checkmark when checked */
.label_main input:checked~.geekmark:after {
  display: block;
}

/* Styling the checkmark using webkit */
/* Rotated the rectangle by 45 degree and 
showing only two border to make it look
like a tickmark */
.label_main .geekmark:after {
  left: 6px;
  bottom: 5px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.button_transparent {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: right;
  column-gap: 0.78vw;
  font-family: Geometria;
  font-size: 1.09vw;
  padding: 0.8vw 1vw;
  font-weight: 500;
  border: none;
  margin-right: 2.65vw;
  border-radius: 4px;
  color: #4475B7;
  font-family: Geometria;
  font-style: normal;
  line-height: normal;
  background-color: transparent;
}

.client_panel_list_table tr .checked {
  text-align: center;
}

.page_list_checkbox {
  margin-right: 1.56vw;
}

.page_list_checkbox .page_list li {
  margin: 0 0 1vw;
}

.page_select_label label {
  text-align: left;

  padding: 0.625vw 0vw;
  color: #4475B7;
  font-family: Geometria;
  font-size: 1.09vw;
  font-weight: 500;
}

.page_list_table {
  width: 5.17vw;
  background-color: #fff;
}

.page_list_table td {
  padding: 0.625vw 0.78vw;
  color: #777;
  font-family: Geometria;
  font-size: 1.09vw;
  font-weight: 500;
}

.page_list_table th {
  text-align: left;
  /* padding: 0.625vw 0.78vw; */
  height: 29px;
  color: #4475B7;
  font-family: Geometria;
  font-size: 1.09vw;
  font-weight: 500;
}

/* App.css */
.custom-modal {
  width: 60%;
  /* Set the width of the modal */
  max-width: 500px;
  /* Set a maximum width if needed */
  height: auto;
  /* Set the height of the modal */
  background-color: white;
  margin: auto;
  /* Center horizontally */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust overlay background color and transparency */
  display: flex;
  justify-content: center;
  align-items: center;
}

ul.model_popup li {
  padding: 0.8vw 0.8vw;
  display: flex;
  align-items: center;
}

ul.model_popup li img {
  margin-right: 0.8vw;
  width: 20px;
  height: 20px;
}

ul.model_popup li:hover {
  border-radius: 4px;
  background: #F7F4FF;
}

.ExportImport {
  cursor: pointer;
}

input:disabled {
  background-color: #f2f2f2 !important;
  color: #999;
}

.w-100px {
  width: 100px;
  display: block;
}

.btn-p {
  background-color: #4476b7 !important;
  border-color: #4476b7 !important;
}