/* Geometria Regular */
@font-face {
  font-family: 'Geometria';
  src: url('./fonts/Geometria.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Geometria Bold */
@font-face {
  font-family: 'Geometria';
  src: url('./fonts/Geometria-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* Geometria Bold Italic */
@font-face {
  font-family: 'Geometria';
  src: url('./fonts/Geometria-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

/* Geometria Extra Bold */
@font-face {
  font-family: 'Geometria';
  src: url('./fonts/Geometria-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

/* Geometria Extra Bold Italic */
@font-face {
  font-family: 'Geometria';
  src: url('./fonts/Geometria-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

/* Geometria Extra Light */
@font-face {
  font-family: 'Geometria';
  src: url('./fonts/Geometria-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

/* Geometria Extra Light Italic */
@font-face {
  font-family: 'Geometria';
  src: url('./fonts/Geometria-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

/* Geometria Heavy */
@font-face {
  font-family: 'Geometria';
  src: url('./fonts/Geometria-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

/* Geometria Heavy Italic */
@font-face {
  font-family: 'Geometria';
  src: url('./fonts/Geometria-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

/* Geometria Italic */
@font-face {
  font-family: 'Geometria';
  src: url('./fonts/Geometria-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

/* Geometria Light */
@font-face {
  font-family: 'Geometria';
  src: url('./fonts/Geometria-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

/* Geometria Light Italic */
@font-face {
  font-family: 'Geometria';
  src: url('./fonts/Geometria-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

/* Geometria Medium */
@font-face {
  font-family: 'Geometria';
  src: url('./fonts/Geometria-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

/* Geometria Medium Italic */
@font-face {
  font-family: 'Geometria';
  src: url('./fonts/Geometria-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

/* Geometria Thin */
@font-face {
  font-family: 'Geometria';
  src: url('./fonts/Geometria-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

/* Geometria Thin Italic */
@font-face {
  font-family: 'Geometria';
  src: url('./fonts/Geometria-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}

input:internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: none !important;
  color: fieldtext !important;
}

.pt-table tr td {
  padding: 0.55vw 0.55vw;
}

.password-toggleUser {
  position: absolute;
  right: 3%;
  top: 50%;
  cursor: pointer;
  color: #555;
}

.top-40 {
  top: 40%;
}

.color {
  color: #F2F2F2;
}

.password-toggle svg {
  font-size: 1.2em;
}

.new_client_menu ul {
  margin-bottom: 0;
}

button {
  cursor: pointer;
}

input[type="submit"] {
  cursor: pointer;
}

:root {
  --c-1: #4475B7;
}

h1 {
  color: #FFF;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* login-start */
.login_wrapper {
  display: flex;
  height: 100vh;
}

.login_form_area {
  width: 53.04vw;
  border-radius: 0px 0px 0px 0px;
  background-color: var(--c-1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login_logo_area {
  width: 46.95vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login_logo {
  margin-bottom: 1.81vw;
}

.login_logo img {
  width: 5.09vw;
  height: 5.09vw;
}

.login_form_area h1 {
  color: #FFF;
  text-align: center;
  font-family: Geometria;
  font-size: 2.9vw;
  font-weight: 500;
  margin-bottom: 2.65vw;
}

.login_logo_area h1 {
  color: #4475B7;
  text-align: center;
  font-family: Geometria;
  font-size: 2.9vw;
  font-weight: 700;
}

.login_logo_area h2 {
  color: #555;
  margin-top: 1.41vw;
  text-align: center;
  font-family: Geometria;
  font-size: 1.50vw;
  font-weight: 400;
}

.login_form_area form {
  width: 35.31vw;
}

.login_form_area input {
  background-color: transparent;
  border: none;
}

.login_form_area input[type="text"],
.login_form_area input[type="password"] {
  margin-bottom: 2.34vw;
  width: 100%;
  color: #EAEAEA;
  border-bottom: 1px solid #F2F2F2;
  padding-bottom: 0.625vw;
}

.login_form_area input[type="text"]::placeholder,
.login_form_area input[type="password"]::placeholder {
  color: #EAEAEA;
  font-family: Geometria;
  font-size: 1.25vw;
  font-weight: 400;
}

.login_form_area input:focus-visible {
  outline: none !important;
}

.login_form_area h3 {
  color: #F2F2F2;
  font-family: Geometria;
  font-size: 1.25vw;
  margin-bottom: 2.03vw;
  font-weight: 500;
}

input {
  border: none;
}

input:focus-visible {
  outline: none !important;
}

.keep_me_forget_pass {
  display: flex;
  justify-content: space-between;
  color: #FFF;
  align-items: center;
}

.checkbox_wrap input {
  background-color: transparent;
  margin-right: 1.14vw;
  visibility: visible !important;
  border-radius: 4px;
  border: 1px solid #789AC9;
}

.login_form_area .submit_button {
  text-align: center;
}

.login_form_area input[type="submit"] {
  text-align: center;
  margin: 0 auto;
  color: #252525;
  font-family: Geometria;
  font-size: 1.25vw;
  font-weight: 500;
  background-color: #fff;
  width: auto !important;
  height: 3.68vw;
  border-radius: 8px;
  margin-top: 2.5vw;
  margin-bottom: 2.18vw;
  padding: 0px 3.68vw;
}

.login_form_area p {
  color: #EAEAEA;
  font-family: Geometria;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* login-end */

.dashboard {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.dashboard-content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

main {
  flex: 1;
  padding: 1.1vw;
  background-color: #f0f0f0;
}

header {
  background-color: #333;
  color: #fff;
  padding: 0px 1.187vw;
}

aside {
  width: 200px;
  background-color: #555;
  color: #fff;
  padding: 15px;
}

.aaaa {
  height: 1000px;
  background-color: red;
  width: 500px;
}

.dashboard {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #F7F4FF;
}

.dashboard-content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

main {
  flex: 1;
  /* padding: 20px; */
  background-color: #fff;
  overflow-y: auto;
  margin-top: 5.6vw;
  margin-left: 17.8vw;
  /* padding: 2.57vw 2.34vw; */
  margin-right: 0.9vw;
  /* Adjust the top margin to make space for header and sidebar */
}

.logout-btn button {
  padding: 0.85vw 2.79vw;
  display: flex;
  align-items: center;
  margin-left: 1.266vw;
  background: none !important;
  color: #555;
  border: none;
  font-size: 18px;
  font-weight: 500;

}

header {
  background-color: #fff;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 4.5vw;
  display: flex;
  align-items: center;
  /* Ensure the header is on top of other elements */
}

aside {
  width: 16.71vw;
  background-color: #fff;
  color: #fff;
  padding: 15px;
  position: fixed;
  top: 4.5vw;
  bottom: 0;
  z-index: 1;
  /* Ensure the sidebar is on top of other elements */
}

.header_wrapper {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.header_logo {
  display: flex;
  column-gap: 0.31vw;
  align-items: center;
  /* margin-right: 4.53vw; */
  width: 16.71vw;
  /* Me */
}

.header_logo h2 {
  color: #4475B7;
  font-family: Geometria;
  font-size: 1.65vw;
  font-weight: 700;
}

.header_logo img {
  width: 2.59vw;
  height: 2.59vw;
}

aside li {
  color: #555;
  font-family: Geometria;
  font-size: 1.30vw;
  font-weight: 500;
  padding: 1.09vw 0.6vw 1.09vw 0.6vw;
  margin-bottom: 0.5vw;
}

aside ul li a {
  color: #555;
  font-family: Geometria;
  font-size: 18px;
  font-weight: 500;

}

aside ul li:hover a {
  color: #fff;
  font-family: Geometria;
  font-size: 18px;
  font-weight: 500;

}


.dashboard-content ul {
  padding-left: 0vw;
}

aside h2 {
  padding-left: 1.4vw;
  color: #333;
  font-family: Geometria;
  font-size: 1.6vw;
  font-weight: 500;
  margin-bottom: 2.75vw;
  margin-top: 2.66vw;
}

.header_title_area {
  margin-right: 8.75vw;
}

.header_title_area h4 {
  color: #333;
  font-family: Geometria;
  font-size: 1.59vw;
  font-weight: 500;
}

.header_date h3 {
  color: #555;
  font-family: Geometria;
  font-size: 1.9vw;
  font-weight: 400;
}

.header_date h3 sup {
  font-size: 0.93vw;
}

.header_date {
  margin-right: 3.71vw;
}

.header_profile_acc {
  margin-right: 3.71vw;
}

.header_profile_acc h5 {
  color: #555;
  font-family: Geometria;
  font-size: 1.56vw;
  font-weight: 400;
}

.header_notification {
  color: #555;
}

.header_down_menu {
  color: #555;
  margin-right: 4.84vw;
}

.header_profile_acc {
  display: flex;
  align-items: center;
  column-gap: 0.7vw;
}

.new_client_title {
  display: flex;
  justify-content: end;
  align-items: center;
}

.new_client_title h2 {
  color: #333;
  font-family: Geometria;
  font-size: 1.71vw;
  font-weight: 500;
}

.radio_button_label h2 {
  color: #555;
  font-family: Geometria;
  font-size: 1.09vw;
  font-weight: 500;
}

.client_tab_title {
  margin: 1.5vw 0;
}

.client_tab_title h2 {
  color: #4475B7;
  font-family: Geometria;
  font-size: 1.375vw;
  font-weight: 500;
}

.new_client_title button {
  color: #4475B7;
  font-family: Geometria;
  column-gap: 0.62vw;
  font-size: 1.093vw;
  font-weight: 500;
  background-color: #fff;
  border-radius: 4px;
  border: 0.5px solid #C9D7E9;
  width: 7.26vw;
  height: 2.8vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new_client_title button svg {
  width: 1.17vw;
  height: 1.17vw;
}

.new_client_menu {
  margin-top: 1.71vw;
  overflow-x: auto;
  white-space: nowrap;
}

.new_client_menu ul {
  display: flex;
  align-items: center;
  column-gap: 5.39vw;
}

.scrollable-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  /* Adjust this value for spacing between tabs */
  overflow-x: auto;
  /* Enable horizontal scrolling */
  scrollbar-width: none;
  /* Hide scrollbar for Firefox */
  -ms-overflow-style: none;
  /* Hide scrollbar for IE and Edge */
}

.scrollable-container::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for Webkit-based browsers */
}


.new_client_menu ul li {
  color: #555;
  font-family: Geometria;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
  border-bottom: 4px solid transparent;
  padding-bottom: 0.93vw;
}

.new_client_menu ul li:hover {
  color: #4475B7;
  border-bottom: 4px solid #4475B7;
}

.new_client_menu ul li.active {
  color: #4475B7;
  border-bottom: 4px solid #4475B7;
}

.new_client_menu {
  border-bottom: 0.5px solid #d0dceb96;
  margin-bottom: 2.34vw;
}

.new_client_part_1 {
  width: 50%;
}

.new_client_part_1 .dashboard_input_feild h3 {
  color: #555;
  font-family: Geometria;
  font-size: 1.09vw;
  font-weight: 500;
}

.dashboard_select_field h3 {
  color: #555;
  font-family: Geometria;
  font-size: 1.09vw;
  font-weight: 500;
}

.dashboard_textarea_feild h3 {
  color: #555;
  font-family: Geometria;
  font-size: 1.09vw;
  font-weight: 500;
}

.dashboard_checkbox_feild label {
  color: #555;
  font-family: Geometria;
  font-size: 1.09vw;
  font-weight: 500;
}

.dashboard_image_feild label {
  color: #555;
  font-family: Geometria;
  font-size: 1.09vw;
  font-weight: 500;
}

.new_client_part_1 .dashboard_input_feild input,
select {
  border-radius: 4px;
  background: #F7F4FF;
  border: none;
  height: 29px;
  width: 100%;
  color: #777;
  font-family: Geometria;
  font-size: 0.93vw;
  font-weight: 400;
  font-size: 0.875rem;
  padding: 1.4vw 0.8vw;
  margin-top: 0.78vw;
}

.form-select {
  padding-top: 0;
}

.dashboard_select_field .form-select {
  background-color: #F7F4FF;
}

.new_client_part_1 .dashboard_textarea_feild textarea {
  border-radius: 4px;
  background: #F7F4FF;
  border: none;
  width: 100%;
  padding: 0.93vw 0.78vw;
  color: #777;
  font-family: Geometria;
  font-size: 0.93vw;
  font-weight: 400;
  margin-top: 0.78vw;
}

.margin-space {
  margin-bottom: 10vw;
}

.radio-button input[type="radio"] {
  margin-right: 0.5vw;
}

.New_client_panel label.radio-button {
  margin-right: 5vw;
}

.New_client_panel .radio_button_label {
  margin-bottom: 0.78vw;
}

.new_client_part_1 .dashboard_input_feild input,
select {
  outline: none !important;
}

.new_client_part_1 .dashboard_input_feild {
  margin-bottom: 1.56vw;
}

.new_client_part_1 .dashboard_select_field {
  margin-bottom: 1.56vw;
}

.new_client_form {
  display: flex;
  column-gap: 5.03vw;

}

.new_client_content_wrapper {
  width: 100%;
}

.new_client_content_wrapper hr {
  height: 0.5px;
  opacity: 0.5;
  border-color: #D0DCEB;
  margin-bottom: 1.32vw;
}

.switch {
  position: relative;
  display: inline-block;
  width: 2.26vw;
  height: 1.17vw;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #F4F4F4;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.01vw;
  width: 1.01vw;
  left: 0.07vw;
  bottom: 0.07vw;
  background-color: #C9D7E9;
  -webkit-transition: .4s;
  transition: .4s;
}


input:focus+.slider {
  box-shadow: 0 0 1px #4475B7;
}

input:checked+.slider:before {
  -webkit-transform: translateX(1.1vw);
  -ms-transform: translateX(1.1vw);
  transform: translateX(1.1vw);
  background-color: #4475B7;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.toggle_switch_btn {
  display: flex;
  justify-content: start;
  margin-bottom: 1.56vw;
  width: 28.82vw;
}

.toggle_switch_btn.gst {
  margin-bottom: 0;
}

hr {
  opacity: 0.2 !important;
}

.toggle_switch_btn h3 {
  color: #555;
  font-family: Geometria;
  font-size: 1.09vw;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 1.56vw;
}

.salary-form {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


aside ul li .menu_text {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}

aside ul li:hover {
  background-color: #4475B7;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}

aside ul li .main_part_menu {
  display: flex;
  column-gap: 1.01vw;
  align-items: center;
  font-size: 18px;
}

aside ul li:hover .submenu li {
  color: #fff;
  color: #E9E9E9;
  font-family: Geometria;
  font-weight: 500;
}

aside ul li .submenu li {
  font-size: 1.09vw;
  margin-bottom: 0;
  padding: 0;
  padding-top: 0.85vw;
}

aside .menu_item .menu_text svg {
  transform: rotate(0deg);
  transition: 0.3s;
}

aside .menu_item.active .menu_text svg {
  transform: rotate(180deg);
}

aside>ul li:hover .main_part_menu.m1 img {
  content: url('./user_white.svg');
}

aside>ul li:hover .main_part_menu.m2 img {
  content: url('./people_white.svg');
}

aside>ul li:hover .main_part_menu.m3 img {
  content: url('./money_white.svg');
}

aside>ul li:hover .main_part_menu.m4 img {
  content: url('./report_white.svg');
}

.logout-btn button {
  position: absolute;
  bottom: 1rem;
  left: 0;
  padding: 0.85vw 0;
  display: flex;
  align-items: center;
  background: none !important;
  color: #555;
  border: none;
  border-radius: 5px;
  font-size: 20px;
  padding: 1rem 1rem 1rem 0.4rem;
}

.logout-btn button:hover {
  width: 85%;
  color: white;
  background-color: #4475b7 !important;
}

.logout-svg {
  padding-right: 0.5rem;
}

.menu_item.active {
  background-color: #4475B7;
}

.menu_item.active * {
  color: #E9E9E9;
  font-family: Geometria;
  font-weight: 500;
}

.main_part_menu img {
  width: 1.2vw;
  height: 1.2vw;
}

.save_button.back {
  background: #fff !important;
  color: #4475B7 !important;
  border: 1px solid #C9D7E9 !important;
}

.save_button.back:hover {
  background: none !important;
  color: none !important;
  border: 1px solid #C9D7E9 !important;
}

.tab1.save_button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  text-align: right;
  column-gap: 0.78vw;
  font-family: Geometria;
  font-size: 1.09vw;
  /* width: 7.26vw; */
  padding: 0.8vw 1vw;
  /* height: 3.125vw; */
  font-weight: 500;
  border: none;
  /* margin-left: auto; */
  margin-right: 2.65vw;
  /* margin-top: 4.87vw; */
  border-radius: 4px;
  background: #4475B7;
}

.btn_save.btn-right {
  text-align: -webkit-right;
  margin: 8vw 8vw 0;
}

.uploaded_files {
  /* border: 1px solid; */
  border-radius: 4px;
  border: 0.5px solid #C9D7E9;
}

.uploaded_files ul {
  padding: 0 1vw;
  margin: 0;
}

.uploaded_files ul li {
  padding: 8px 0;
}

.uploaded_files ul li .file_name {
  color: #555;
  font-size: 1.09vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.tab1.upload-btn {
  padding: 0.8vw 1.9vw
}

.header_notification svg {
  opacity: 0.5;
  transform: scale(1.2);
}

.admin_dashboard_main main {
  background-color: transparent;
  padding: 0;
  padding-right: 2.42vw;
  position: relative;
}

.main_menu_admin_dashboard {
  position: fixed;
  width: 78.39vw;
}

.main_menu_admin_dashboard ul {
  display: flex;
  align-items: center;
  column-gap: 5.39vw;
}

.main_menu_admin_dashboard ul li {
  color: #555;
  font-family: Geometria;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
  border-bottom: 4px solid transparent;
  padding-bottom: 1.25vw;
  padding-top: 1.25vw;
}

.main_menu_admin_dashboard ul li:hover {
  color: #4475B7;
  border-bottom: 4px solid #4475B7;
}

.main_menu_admin_dashboard ul li.active {
  color: #4475B7;
  border-bottom: 4px solid #4475B7;
}

.main_menu_admin_dashboard {
  background-color: #fff;
  border-radius: 6px;
  border: 0.5px solid rgba(120, 154, 201, 0.40);
  background: #FFF;
  padding-left: 4.53vw;
}

.admin_dashboard_status {
  margin-top: 6.18vw;
  display: flex;
  column-gap: 3.28vw;
}

.admin_dashboard_status_part1 {
  background-color: #fff;
  border-radius: 6px;
  border: 0.5px solid rgba(120, 154, 201, 0.40);
  padding: 2.42vw 4.53vw;
}

.admin_dashboard_status .admin_dashboard_status_part1 .admin_dashboard_status_part1_line1 {
  margin-bottom: 1.25vw;
}

.admin_dashboard_status .admin_dashboard_status_part1 .admin_dashboard_status_part1_line1,
.admin_dashboard_status .admin_dashboard_status_part1 .admin_dashboard_status_part1_line2 {
  display: flex;
  column-gap: 1.79vw;
}

.admin_dashboard_status1,
.admin_dashboard_status2,
.admin_dashboard_status3,
.admin_dashboard_status4 {
  border-radius: 6px;
  display: flex;
  align-items: center;
  border: 0.5px solid rgba(120, 154, 201, 0.40);
  width: 22.81vw;
}

.admin_dashboard_status1_count,
.admin_dashboard_status2_count,
.admin_dashboard_status3_count,
.admin_dashboard_status4_count {
  padding: 0.85vw;
  border-right: 0.5px solid rgba(120, 154, 201, 0.40);
  ;
}

.admin_dashboard_status1_circle,
.admin_dashboard_status2_circle,
.admin_dashboard_status3_circle,
.admin_dashboard_status4_circle {
  width: 3.28vw;
  height: 3.28vw;
  display: flex;
  background-color: #4475B7;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
}

.admin_dashboard_status1_circle h2,
.admin_dashboard_status2_circle h2,
.admin_dashboard_status3_circle h2,
.admin_dashboard_status4_circle h2 {
  color: #FFF;
  font-family: Geometria;
  font-size: 1.61vw;
  font-weight: 500;
}

.admin_dashboard_status_part1 {
  width: 56.40vw;
}

.admin_dashboard_status1_title p,
.admin_dashboard_status2_title p,
.admin_dashboard_status3_title p,
.admin_dashboard_status4_title p {
  color: #777;
  font-family: Geometria;
  font-size: 1.09vw;
  font-weight: 500;
}

.admin_dashboard_status1_title,
.admin_dashboard_status2_title,
.admin_dashboard_status3_title,
.admin_dashboard_status4_title {
  padding: 1.32vw 1.01vw;
}

.main_dashboard_date {
  border-radius: 6px;
  border: 0.5px solid rgba(120, 154, 201, 0.40);
  background: #FFF;
  display: flex;
  width: 18.59vw;
  align-items: center;
}

.date_admin_dashboard {
  padding: 1.32vw 1.17vw;
  display: flex;
  align-items: center;
  column-gap: 0.625vw;
}

.date_admin_dashboard h3 {
  color: #777;
  font-family: Geometria;
  font-size: 1.09vw;
  font-weight: 500;
}

.date_admin_icon {
  padding: 1.69vw;
  border-left: 0.5px solid rgba(120, 154, 201, 0.40);
}

.date_admin_icon svg {
  color: #4475B7;
}

.admin_dashboard_top_5_wrap {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2.34vw;
  column-gap: 3.67vw;
}

.admin_dashboard_top_5_wrap h2 {
  width: 100%;
  margin-bottom: 1.56vw;
  color: #333;
  font-family: Geometria;
  font-size: 1.40vw;
  font-weight: 500;
}

.admin_dashboard_top_5_1 {
  width: 32.96vw;
  border-radius: 6px;
  border: 0.25px solid rgba(120, 154, 201, 0.20);
  background-color: #fff;
}

.admin_dashboard_top_5_1 th {
  text-align: left;
  border: 0.25px solid rgba(120, 154, 201, 0.20);
  padding: 0.625vw 0.78vw;
  color: #4475B7;
  font-family: Geometria;
  font-size: 1.09vw;
  font-weight: 500;
}

.admin_dashboard_top_5_1 td {
  padding: 0.625vw 0.78vw;
  border: 0.25px solid rgba(120, 154, 201, 0.20);
  color: #777;
  font-family: Geometria;
  font-size: 1.09vw;
  font-weight: 500;
}

.admin_dashboard_top_5_wrap_2 {
  margin-bottom: 0.78vw;
}

.admin_dashboard_top_5_wrap_2 table {
  width: 69.60vw;
}

.admin_dashboard_top_5_wrap_2 table tr span {
  color: #FFF;
  font-family: Geometria;
  font-size: 1.09vw;
  border-radius: 3px;
  opacity: 0.9;
  background: #48AF7E;
  font-style: normal;
  padding: 0.39vw 1.48vw;
  font-weight: 500;
  line-height: normal;
}

.client_panel_list_table {
  width: 100%;
  border-radius: 6px;
  border: 0.25px solid rgba(120, 154, 201, 0.20);
  margin-top: 1.56vw;
  background-color: #fff;
}

.client_panel_list_table th {
  text-align: left;
  border: 0.25px solid rgba(120, 154, 201, 0.20);
  padding: 0.55vw 0.55vw;
  color: #4475B7;
  font-family: Geometria;
  font-size: 14px;
  font-weight: 500;
}

.client_panel_list_table td .edit {
  background: none !important;
  color: #4475B7;
  border: none;
}

.client_panel_list_table td .delete {
  background: none !important;
  color: #dc3545;
  border: none;
}

.client_panel_list_table td {
  padding: 0.2vw 0.5vw;
  border: 0.25px solid rgba(120, 154, 201, 0.20);
  color: #777;
  font-family: Geometria;
  font-size: 14px;
  font-weight: 500;
}

.client_panel_menu .client_panel_title h2 {
  color: #333;
  font-family: Geometria;
  font-size: 1.71vw;
  font-weight: 500;
}

.client_panel_menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.client_panel_search input {
  height: 2.8vw;
  width: 33.61vw;
  /* width: 28.31vw; */
  border-radius: 4px;
  border: 1px solid #D0DCEB;
  background: #FFF;
  color: #777;
  font-family: Geometria;
  font-size: 1.09vw;
  font-weight: 500;
  /* margin-left: 1.32vw;
  margin-right: 1.56vw; */
  padding-left: 1.17vw;
}

.client_panel_menu button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.85vw 0;
  column-gap: 0.93vw;
}

.client_panel_option {
  margin-right: 1.79vw;
}

.client_panel_column_chooser {
  margin-right: 1.56vw;
}

.static-image {
  width: 50px;
  height: auto;
}

.image-upload {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
}

.upload-label {
  cursor: pointer;
  background-color: #F7F4FF;
  color: #4475B7;
  font-family: Geometria;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 6vw 2vw;
  border-radius: 5px;
  width: 100%;
  text-align: center;
}

.image-preview {
  margin-top: 20px;
}

.image-preview img {
  max-width: 100%;
  max-height: 200px;
}

.password_wrap span {
  color: #fff;
}

.header_profile_acc h5 {
  margin-bottom: 0;
}

img,
svg {
  vertical-align: middle !important;
}

body {
  line-height: unset !important;
}

.border-none {
  border: none !important;
}

.d-center {
  display: flex;
  justify-content: center;
}

.emp_entry_tbl input {
  width: 100%;
}

.login_form_area p a {
  color: #fff !important;
  text-decoration: none !important;
  font-weight: 500;
}

/* Wizard CSS */
.css-sghohy-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: #4475b7;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: #4475b7 !important;
  box-shadow: none !important;
  color: #fff !important;
  font-size: 1.09vw !important;
  font-weight: 500 !important;
  padding: 0.8vw 1vw !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: #4475b7 !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  width: 2.5vw !important;
  height: 2.5vw !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #4475b7 !important;
}

.MuiStepConnector-horizontal.Mui-active .MuiStepConnector-line {
  border-color: #4475b7 !important;
}

.MuiStepConnector-horizontal.Mui-completed .MuiStepConnector-line {
  border-color: #4475b7 !important;

}

.css-z7uhs0-MuiStepConnector-line {
  display: block;
  border-color: #bdbdbd;
  border-top-style: solid;
  border-top-width: 3px !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #4475b7 !important;
}

.MuiStepConnector-active {
  color: #007bff !important;
  /* Change this to the color you want */
}

.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
  outline: none !important;
  outline-offset: none !important;
  mask-border-outset: none !important;
}

.css-18nc3u2 .MuiOutlinedInput-root:hover {
  border: none !important;
}

.MuiOutlinedInput-root {
  outline: -webkit-focus-ring-color auto 0px !important;
  outline: none !important;
  outline-offset: none !important;
  mask-border-outset: none !important;
}

/* ------------------Responsive css------------------ */

@media (max-width: 468px) {
  .login_wrapper {
    flex-direction: column;
  }

  .login_logo_area {
    width: 100%;
    padding-top: 8vw;
    padding-bottom: 8vw;
  }

  .login_form_area {
    width: 100%;
    border-radius: 30px 30px 0px 0px;
    height: 100%;
    justify-content: flex-start;
  }

  .login_logo img {
    width: 25.09vw;
    height: 25.09vw;
  }

  .login_logo_area h1 {
    font-size: 10vw;
  }

  .login_logo_area h2 {
    font-size: 4vw;
    margin-top: 0;
  }

  .login_form_area h1 {
    font-size: 8.125vw;
    margin-top: 10vw;
  }

  .login_form_area form {
    width: 81.31vw;
  }

  .login_form_area h3 {
    font-size: 4.25vw;
  }

  .login_form_area input[type="text"]::placeholder,
  .login_form_area input[type="password"]::placeholder {
    font-size: 3.25vw;
  }

  .login_form_area h3 {
    margin-bottom: 1.03vw;
    margin-top: 3vw;
  }

  .login_form_area form {
    margin-top: 4vw;
  }

  .checkbox_wrap span {
    font-size: 3.5vw;
  }

  .password_wrap span {
    font-size: 3.5vw;
    color: white;
  }

  .login_form_area input[type="submit"] {
    font-size: 4.25vw;
    width: 34.18vw;
    height: 9.68vw;
    border-radius: 26px;
  }

  .login_form_area p {
    font-size: 3.54vw;
  }

  .login_form_area input[value="Change password"] {
    width: 54.18vw;
  }

}

.error {
  margin-top: -1vw;
  margin-bottom: 1vw;
  color: red;
}

.gapbetween {
  width: 1.40vw;
}

.otp_wrapper form div input {
  width: 4.68vw !important;
}

.Toastify__toast-icon img {
  width: 2vw;
}

a {
  text-decoration: none;
}